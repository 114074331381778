import React from 'react'

import Modal from '../../Modal/Modal'
import splashImage from '../../../assets/images/RollingDoodle.png'

const TermsModal = ({ onClose }: any): JSX.Element => {
  return (
    <Modal onClose={onClose}>
      <div className="submit-modal">
        <div>
          <img src={splashImage} alt="splash" className="fade-in-bottom dl-2" />
        </div>
        <div>
          <h1 className="fade-in-bottom dl-4">Thanks for being awesome!</h1>
          <p className="fade-in-bottom dl-6">
            We appreciate you contacting us at Ayala Land International Sales, Inc. One of our colleagues will get back in touch with you
            soon!
          </p>
          <p className="fade-in-bottom dl-8">Have a great day!</p>
        </div>
      </div>
    </Modal>
  )
}

export default TermsModal
