export const links = [
  {
    name: 'Contact Form',
    url: '/contact-us'
  },
  {
    name: 'International Offices',
    url: '/contact-us/international-offices'
  },
  {
    name: 'Local Offices',
    url: '/contact-us/local-offices'
  }
]
