import React from 'react'

import Modal from '../../Modal/Modal'
import { graphql, useStaticQuery } from 'gatsby'

const TermsModal = (props: any) => {
  const termsModalData = useStaticQuery(graphql`
    query {
      termsAndConditions {
        id
        name
        containers {
          name
          field_collections {
            name
            fields {
              name
              value
            }
          }
        }
      }
    }
  `)

  const termsAndConditions = termsModalData.termsAndConditions.containers[0].field_collections[0].fields

  return (
    <Modal onClose={props.onClose}>
      <h1>{termsAndConditions[0].value}</h1>

      <div
        dangerouslySetInnerHTML={{
          __html: termsAndConditions[1] ? termsAndConditions[1].value : ''
        }}
      />
    </Modal>
  )
}

export default TermsModal
