import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'

import Modal from '../../Modal/Modal'

interface PrivacyModalType {
  onClose: (() => void) | undefined
}

const PrivacyModal = ({ onClose }: PrivacyModalType): JSX.Element => {
  const privacyPolicyData = useStaticQuery(graphql`
    query {
      privacyPolicy {
        id
        name
        containers {
          name
          field_collections {
            name
            fields {
              name
              value
            }
          }
        }
      }
    }
  `)

  const privacyPolicy = privacyPolicyData.privacyPolicy.containers[0].field_collections[0].fields
  const title = privacyPolicy[0].value
  const body = privacyPolicy[1].value

  return (
    <Modal onClose={onClose}>
      <h1>{title}</h1>
      <div dangerouslySetInnerHTML={{ __html: body }} />
    </Modal>
  )
}

export default PrivacyModal
