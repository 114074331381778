/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'

import './Modal.scss'

const ModalComponent = (props: any): JSX.Element => {
  const { onClose, children } = props
  return (
    <>
      <div className="paper-container">
        <div className="paper fade-in-bottom">
          <div className="close-icon">
            <i className="la la-close close-btn" onClick={onClose} />
          </div>
          <div>{children}</div>
        </div>
      </div>
      <div className="modal-container" onClick={onClose} />
    </>
  )
}

export default ModalComponent
