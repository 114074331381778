import { graphql, useStaticQuery } from 'gatsby'
import React, { ReactElement } from 'react'

import ContactUs from '../../../components/Layout/ContactUs'
import LocalOffices from '../../../components/ContactUsPage/LocalOffices'
import SEO from '../../../components/SEO/SEO'

const LocalOfficesPage = (): ReactElement => {
  const contactUsHeroBanner = useStaticQuery(graphql`
    query {
      heroBanner(title: { eq: "Contact Us" }) {
        image
      }
    }
  `)

  return (
    <ContactUs heroImage={contactUsHeroBanner.heroBanner ? contactUsHeroBanner.heroBanner.image : ''}>
      <SEO title="LOCAL OFFICES - ALISI | Ayala Land" />
      <LocalOffices />
    </ContactUs>
  )
}

export default LocalOfficesPage
