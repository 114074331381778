import React, { ReactElement, SetStateAction } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import './Layout.scss'
import './Animations.scss'
import Hero from '../AboutUsPage/Hero'
import ContentContainer from '../ContentContainer'

type LayoutPropTypes = {
  children?: string | Element | ReactElement | boolean | (string | Element | ReactElement | boolean)[]
  heroImage?: string
  onCloseTerms?: () => void
  onClosePrivacy?: () => void
  onCloseSubmit?: () => void
  termsModal?: boolean
  privacyModal?: boolean
  submitModal?: boolean
}

import TermsModal from '../ContactUsPage/ContactForm/TermsModal'
import PrivacyModal from '../ContactUsPage/ContactForm/PrivacyModal'
import SubmitModal from '../ContactUsPage/ContactForm/SubmitModal'

const ContactUs = ({
  children,
  heroImage,
  onCloseTerms,
  termsModal,
  onClosePrivacy,
  privacyModal,
  onCloseSubmit,
  submitModal
}: LayoutPropTypes): ReactElement => (
  <>
    <Header />
    {termsModal && <TermsModal onClose={onCloseTerms} />}
    {privacyModal && <PrivacyModal onClose={onClosePrivacy} />}
    {submitModal && <SubmitModal onClose={onCloseSubmit} />}
    <Hero heroImage={heroImage} />
    <ContentContainer>{children}</ContentContainer>
    <Footer />
  </>
)

export default ContactUs
